/* src/App.css */

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
  
  main {
    flex-grow: 1;
    padding-bottom: 60px; /* Ensure footer space */
  }
  
/* Ensure the app takes up the full height of the viewport */
#root, .app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Ensure the main content expands to fill available space */
.main-content {
  flex-grow: 1;
  padding-bottom: 60px; /* Give enough space for the footer */
}

/* Adjusted footer styles for proper alignment */
.footer {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 0;
}

.footer-section {
  flex: 1;
  margin: 0 15px;
  min-width: 150px;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 14px;
  color: #ff4b2b;
}

.footer-section p {
  font-size: 12px;
  margin: 5px 0;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-logout-button {
  background: none;
  color: #ff4b2b;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.footer-logout-button:hover {
  text-decoration: underline;
}

.footer-bottom {
  font-size: 12px;
  color: #bbb;
  border-top: 1px solid #444;
  padding-top: 5px;
  margin-top: 5px;
}
