/* AppointmentCalendar.css */

/* Highlight booked or unavailable dates with a red circle */
.highlight-day {
    position: relative;
  }
  
  .highlight-day::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px; /* Adjust this to match the calendar day size */
    height: 30px; /* Adjust this to match the calendar day size */
    transform: translate(-50%, -50%);
    border: 2px solid red;
    border-radius: 50%;
    pointer-events: none;
  }
  